import React from 'react'
import styled, { css } from 'styled-components'
// import heroThumbnail from '../assets/hero_thumbnail.png'
// @ts-ignore
import heroVideo from '../assets/draumaprinsinn2.mp4'
import { TextImageSection } from '../components/TextImageSection'

export const Home: React.FC = () => {
  return (
    <Root>
      <VideoWrapper className="video-wrapper">
        <HeroTextWrapper>
          <HeroLogo src={require('../assets/logos.png')} />
          <h1 className="hero-heading">kvennakórinn katla</h1>
          <p className="hero-subtitle">
            - allt er gott og ekkert skiptir máli -
          </p>
        </HeroTextWrapper>
        {/* <MouseScrollIcon>
          <div className="mousey">
            <div className="scroller"></div>
          </div>
        </MouseScrollIcon> */}
        <VideoOverlay className="video-overlay" />
        {/* <VideoOverlayImage src={require('../assets/logo_test.png')} /> */}
        {/* </VideoOverlay> */}
        <Video className="video" src={heroVideo} autoPlay muted loop></Video>
      </VideoWrapper>
      {/* <div className="hero-heading-container">
        <h1 className="hero-heading">kvennakórinn katla</h1>
        <p className="hero-subtitle">- Ný heimasíða er í smíðum -</p>
      </div> */}
      {/* <a
        className="hero-link"
        href="mailto:kvennakorinn.katla@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        kvennakorinn.katla@gmail.com
      </a> */}
      <TextImageSection
        photoPosition="right"
        id="korinn"
        title="um kórinn"
        text="Kvennakórinn Katla var stofnaður árið 2012 af konum sem vildu hittast reglulega, syngja saman og stuðla að valdeflandi samfélagi kvenna í gegnum söng. Kórinn telur tæplega 90 konur, flestar á aldrinum 20-45 ára, sem koma úr ýmsum áttum og eiga það sameiginlegt að njóta söngs og sköpunar með hugrekki og kraft að leiðarljósi. Kórinn tekst á við ólíkan tónlistarstíl óhikað og fer ótroðnar slóðir í útsetningum og sviðsframkomu. Allir kórmeðlimir eru virkir þátttakendur í sköpuninni og er reynt að finna það sérstaka í hverri og einni og það nýtt til að gera samhljóm kórsins einstakan. Meðlimir kórsins láta sig samfélagið varða, styðja við hvers konar jafnréttisbaráttu og leggja góðgerðarmálum lið."
      >
        <Image
          alignment={'right'}
          src={require('../assets/um-korinn.jpg')}
        ></Image>
      </TextImageSection>
      <TextImageSection
        photoPosition={'left'}
        id="styrurnar"
        title="hildigunnur einarsdóttir"
        text="Hildigunnur Einarsdóttir, kórstýra og alt, lauk burtfararprófi frá Söngskólanum í Reykjavík undir handleiðslu Signýjar Sæmundsdóttur og Ólafar Kolbrúnar Harðardóttur og stundaði framhaldsnám hjá Janet Williams í Berlín og hjá Jóni Þorsteinssyni í Utrecht. Hún hefur einnig lokið B.A. prófi í skapandi tónlistarmiðlun frá Listaháskóla Íslands. Hildigunnur hefur verið áberandi í kórastarfi undanfarin ár en hún er félagi í Schola Cantorum, Cantouque, Barbörukórnum og kór Íslensku óperunnar. Hún stjórnar Kvennakórnum Kötlu ásamt Lilju Dögg Gunnarsdóttur og er leiðbeinandi og söngkennari hjá söngskólanum Domus vox. Hildigunnur kemur reglulega fram sem einsöngvari með kórum og hljóðfæraleikurum og mun fara með hlutverk Grimgerde í Valkyrjunum eftir Wagner á Listahátíð 2022. Hildigunnur var tilnefnd til íslensku tónlistaverðlaunanna 2014 sem söngkona ársins."
      >
        <Image
          alignment={'left'}
          src={require('../assets/hildigunnur.jpg')}
        ></Image>
      </TextImageSection>
      <TextImageSection
        photoPosition="right"
        id=""
        title="lilja dögg gunnarsdóttir"
        text="Lilja Dögg Gunnarsdóttir, kórstýra og alt, lauk burtfararprófi frá Söngskólanum í Reykjavík árið 2010 undir handleiðsu Elísabetar F. Eiríksdóttur og Mastersprófi frá LHÍ í Sköpun, miðlun og frumkvöðlastarfi þar sem hún lagði áherslu á skapandi kórastarf. Hún starfar eingöngu við tónlist og kemur reglulega fram sem einsöngvari í ýmsum verkum og verkefnum, hún er til dæmis aðalsöngkonan í Umbru, hljómsveit sem sérhæfir sig í fornri tónlist í eigin útsetningum og hefur gefið út þrjár plötur, allar tilnefndar til íslensku tónlistarverðlaunanna og ein hlaut þau. Lilja Dögg er virk í kórastarfi, en ásamt því að vera meðlimur í atvinnusönghópnum Schola Cantorum syngur hún í Cantoque Ensemble. Hún hefur víðtæka reynslu í tónlistarsköpun á sviði miðlunar og kennslu. Ásamt því að stýra Kötlunum stjórnar hún tónsmiðjum í framhaldskólum, Kór Kvennaskólans, kór heldri borgara og kennir söng í einkakennslu. Þá hefur Lilja leitt vinnustofur í kórsöng víða og tekið að sér tónlistarstjórnun í leiksýningum, t.d. Í Gaflaraleikhúsinu og einnig hinar ýmsu uppsetningar á framhaldsskólasöngleikjum. Einnig starfar hún við upptökutengd verkefni og útsetningar á kórtónlist."
      >
        <Image
          alignment={'right'}
          src={require('../assets/lilja_minnkud.jpg')}
        ></Image>
      </TextImageSection>

      <TextImageSection
        photoPosition={'left'}
        id="gigg"
        title="gigg"
        text="Kvennakórinn Katla hefur í gegnum tíðina tekið að sér margs konar verkefni og gigg. Við tökum að okkur þetta hefðbundna eins og að syngja á árshátíðum, jólagleðum, brúðkaupum, afmælum, allt sem ykkur dettur í hug. Við höfum einnig mikla reynslu í fjölbreyttum listgjörningum og elskum að taka þátt í öllu slíku. Til að fá smjörþefinn af því sem við höfum gert þá mælum við með að skoða myndböndin á facebook síðu okkar:"
        url="https://www.facebook.com/kvennakorinnkatla/videos"
        urlText="Kvennakórinn Katla á Facebook"
      >
        <Image alignment={'left'} src={require('../assets/gigg.jpg')}></Image>
      </TextImageSection>
      <TextImageSection
        photoPosition="right"
        id="hafa-samband"
        title="láttu í þér heyra"
        text="Ef þú hefur áhuga á að fá okkur í heimsókn, vinsamlegast hafið samband í gegnum tölvupóstinn okkar kvennakorinn.katla@gmail.com eða með því að senda okkur skilaboð á facebooksíðu okkar:"
        url="https://www.facebook.com/kvennakorinnkatla"
        urlText="Kvennakórinn Katla á Facebook"
      >
        <Image
          alignment={'right'}
          src={require('../assets/asti_uppgjor.jpg')}
        ></Image>
      </TextImageSection>
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  min-height: 100vh;
  overflow-x: scroll;
  overflow-y: hidden;
  /* background-color: rgba(200, 198, 201, 0.8); */
  background-color: white;

  @media screen and (max-width: 700px) {
    .hero-heading {
      font-size: 3rem;
    }
  }
`

const VideoWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  /* TODO: make video static image on mobile? Or just have a much smaller version of the hero video? */
  @media (max-width: 767px) {
    background: url('../assets/hero_thumbnail.png');
    background-size: cover;
  }
`

const HeroTextWrapper = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  & > h1 {
    margin-top: 0;
  }
  @media (max-width: 767px) {
    & > p {
      font-size: 1rem;
    }
  }
`
const HeroLogo = styled.img`
  height: 10rem;
  width: 10rem;
  @media (max-width: 767px) {
    height: 6rem;
    width: 6rem;
  }
`
// const MouseScrollIcon = styled.div`
//   position: absolute;
//   bottom: 0;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   margin: auto;

//   width: 34px;
//   height: 55px;
//   z-index: 100;

//   .mousey {
//     width: 3px;
//     padding: 10px 15px;
//     height: 35px;
//     border: 2px solid black;
//     border-radius: 25px;
//     opacity: 0.75;
//     box-sizing: content-box;
//   }
//   .scroller {
//     width: 3px;
//     height: 10px;
//     border-radius: 25%;
//     background-color: black;
//     animation-name: scroll;
//     animation-duration: 2.2s;
//     animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
//     animation-iteration-count: infinite;
//   }
//   @keyframes scroll {
//     0% {
//       opacity: 0;
//     }
//     10% {
//       transform: translateY(0);
//       opacity: 1;
//     }
//     100% {
//       transform: translateY(15px);
//       opacity: 0;
//     }
//   }
// `

const VideoOverlay = styled.span`
  position: absolute;
  right: 0;
  /* background-color: rgba(225, 173, 157, 0.8); */
  background-color: rgba(207, 156, 160, 0.8);
  z-index: 50;

  /* background-color: rgba(200, 198, 201, 0.8); */

  /* background-image: url('../assets/hero.png'); */
  height: 100%;
  width: 100%;
`
const Video = styled.video`
  width: 100%;
  height: auto;

  @media (min-aspect-ratio: 16/9) {
    width: 100%;
    height: auto;
  }

  @media (max-aspect-ratio: 16/9) {
    width: auto;
    height: 100%;
  }

  @media (max-width: 767px) {
    display: none;
  }

  /* @media (max-width: 400px) {
    position: absolute;
    top: 0;
    left: -50%;
  } */
`

const VideoOverlayImage = styled.img`
  position: absolute;
  top: 5rem;
  right: 3rem;
  max-height: 61rem;
  opacity: 0.7;
  @media (max-width: 400px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 25rem;
  }
`
// const PhotoLeftWrapper = styled.div`
// `

const Image = styled.img<{ alignment: 'right' | 'left' }>`
  ${({ alignment }) => css`
    height: 100%;
    width: 100%;
    border-radius: ${alignment === 'left' ? '1rem 0 0 1rem' : '0 1rem 1rem 0'};
    object-fit: cover;

    @media (max-width: 400px) {
      border-radius: 1rem;
    }
  `}
`
