import React from 'react'
import styled from 'styled-components'

export const Program: React.FC = () => {
  return (
    <Root>
      <PageHeadingContainer>
        <PageTitle>Ástir og Uppgjör</PageTitle>

        <PageInstructions>
          <ProgramList>
            <ProgramListItem>
              <strong>Serenade</strong> - Emiliana Torrini
              <ProgramListInfo>Útsetning: SRJ</ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Sweet Dreams</strong> - Eurythmics
              <ProgramListInfo>Aðlöguð útsetninga kórstýra</ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Wuthering Heights</strong> - Kate Bush
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Draumaprinsinn</strong> - Ragnhildur Gísladóttir
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Brúðkaupslagið</strong> - Todmobile
              <ProgramListInfo>
                Útsetning: Hildigunnur Einarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Hvað um mig og þig?</strong> - Ragnhildur Gísladóttir
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>When the Party's Over</strong> - Billie Eilish
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Don't Kill My Vibe</strong> - Sigrid
              <ProgramListInfo>
                Útsetning: Hildigunnur Einarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>There Must Be An Angel (Playing With My Heart)</strong> -
              Eurythmics
              <ProgramListInfo>
                Útsetning: Hildigunnur Einarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Work Song</strong> - Hozier
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Isobel</strong> - Björk
              <ProgramListInfo>
                Útsetning: Hildigunnur Einarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>No Roots</strong> - Alice Merton
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Can't Stop the Feeling!</strong> - Justin Timberlake
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Waterfountain</strong> - tUnE-yArDs
              <ProgramListInfo>Útsetning: María Magnúsdóttir</ProgramListInfo>
            </ProgramListItem>
          </ProgramList>
        </PageInstructions>
      </PageHeadingContainer>
      <AdSection>
        <AdWrapper>
          <AdLink href="https://www.islandshotel.is/">
            <Ad src={require('../assets/islandshotel-smaller.jpg')}></Ad>
          </AdLink>
        </AdWrapper>
        <AdWrapper>
          <Ad src={require('../assets/paagen.jpg')}></Ad>
        </AdWrapper>
        <AdWrapper>
          <AdLink href="https://www.omnomchocolate.com/">
            <Ad src={require('../assets/omnom-smaller.jpg')}></Ad>
          </AdLink>
        </AdWrapper>
        <AdWrapper>
          <Ad src={require('../assets/rvk-an-texta.jpg')}></Ad>
        </AdWrapper>
      </AdSection>
    </Root>
  )
}

const Root = styled.div`
  font-family: Geometria;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: rgba(225, 173, 157, 0.8);
  padding: 2rem 4rem;
  min-height: 100vh;

  .border-bottom {
    border-bottom: 0.125rem solid rgba(128, 128, 128, 0.2);
  }

  @media screen and (max-width: 800px) {
    padding: 1.5rem;
  }
`
const PageHeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  max-width: 65rem;

  @media screen and (max-width: 800px) {
    margin-bottom: 1rem;
  }
`
const PageTitle = styled.h1`
  font-family: Geometria-Medium;
  font-size: 4rem;

  @media screen and (max-width: 800px) {
    font-size: 3rem;
  }
`

const PageInstructions = styled.div`
  font-size: 1.25rem;
`

const ProgramList = styled.ol`
  font-size: 1rem;
  text-align: left;
  padding-left: 0;
  margin-left: 4rem;
  margin-bottom: 5rem;

  @media screen and (max-width: 800px) {
    margin-left: 2rem;
  }
`

const ProgramListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;

  & > a {
    text-decoration: none;
    font-size: 1rem;
    color: black;

    &:hover {
      text-decoration: underline;
    }
  }
`

const ProgramListInfo = styled.p`
  font-size: 0.875rem;
  margin-top: 0.25rem;
`

const AdSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 0 5rem 0;

  @media screen and (max-width: 1620px) {
    color: magenta;
  }
`

const AdWrapper = styled.div`
  margin: 0 0.5rem;

  @media screen and (max-width: 1620px) {
    color: magenta;
  }

  @media screen and (max-width: 900px) {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  @media screen and (max-width: 768px) {
    margin: 0 0 0.5rem 0;
    width: 100%;
  }
`

const AdLink = styled.a`
  max-height: 100%;
  max-width: 100%;
`

const Ad = styled.img`
  max-width: 100%;
  max-height: 100%;

  /* @media screen and (min-width: 800px) {
    max-width: 55vw;
  } */

  @media screen and (min-width: 1310px) {
    height: 22rem;
  }

  @media screen and (max-width: 900px) {
    margin: 0;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
    width: 100%;
  }
`

/* #e1ad9d */
