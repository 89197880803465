import React from 'react'
import styled from 'styled-components'

export const Sales2022: React.FC = () => {
  return (
    <Root>
      <PageHeadingContainer>
        <PageTitle>Allt er gott og ekkert skiptir máli</PageTitle>
        <PageDescription>
          Kvennakórinn Katla blæs til fjáröflunar vegna ferðalaga kórsins á
          vormánuðum, þar sem landinn verður heillaður með ægifögrum söng 🎶
        </PageDescription>
        {/* <DescriptionList>
          <DescriptionListItem>
            💚 Fjölnota taupoki merktur með merki og slagorði kórsins "Allt er
            gott og ekkert skiptir máli".
          </DescriptionListItem>
          <DescriptionListItem>
            💚 Vörur frá versluninni <a href="https://mena.is">Mena</a> sem
            sérhæfir sig í umhverfisvænum vörum.
          </DescriptionListItem>
          <DescriptionListItem>
            💚 Vörur frá Omnom og Kaffitári í umhverfisvænum umbúðum.
          </DescriptionListItem>
        </DescriptionList> */}
        <PageInstructions>
          Hægt er að nálgast þessar forláta vörur á þrjá mismunandi vegu:
          <InstructionsList>
            <InstructionsListItem>
              Það er hægt að senda tölvupóst á{' '}
              <a
                href="mailto:kvennakorinn.katla@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                kvennakorinn.katla@gmail.com
              </a>
            </InstructionsListItem>
            <InstructionsListItem>
              Eða skrifa skilaboð eða kommenta á{' '}
              <a
                href="https://www.facebook.com/kvennakorinnkatla/"
                target="_blank"
                rel="noopener noreferrer"
              >
                facebook-síðu kórsins
              </a>
            </InstructionsListItem>
            <InstructionsListItem>
              Nú eða heyra í uppáhalds Kötlunni þinni
            </InstructionsListItem>
          </InstructionsList>
        </PageInstructions>
      </PageHeadingContainer>
      <BagContainer className="border-bottom" columnReverse>
        <BagTextWrapper>
          <BagName>Tækifæriskort</BagName>
          <BagDescription>
            <BagListItem>
              Kort með merki og slagorði kórsins "Allt er gott og ekkert skiptir
              máli". Kortin koma fjögur saman í pakka.
            </BagListItem>
          </BagDescription>
          <BagPrice>1.500 kr.</BagPrice>
        </BagTextWrapper>
        <BagImageContainer>
          <BagImage src={require('../assets/fjaroflun/kort.png')} />
        </BagImageContainer>
      </BagContainer>
      <BagContainer className="border-bottom">
        <BagImageContainer leftAligned>
          <BagImage src={require('../assets/fjaroflun/nailberry-2-2.png')} />
        </BagImageContainer>
        <BagTextWrapper rightAligned>
          <BagName>Naglalökk x2</BagName>
          <BagDescription>
            <BagListItem>
              Pakki með tveimur naglalökkum frá Nailberry, sérvöldum af Kötlum.
              Naglalökkin eru í litunum Flapper og Rouge.
            </BagListItem>
          </BagDescription>
          <BagPrice>5.000 kr.</BagPrice>
        </BagTextWrapper>
      </BagContainer>
      <BagContainer className="border-bottom" columnReverse>
        <BagTextWrapper>
          <BagName>Naglalökk í gjafaöskju</BagName>
          <BagDescription>
            <BagListItem>
              Falleg gjafaaskja frá Nailberry með fjórum lökkum sérvöldum af
              Kötlum. Lökkin eru í litunum Pop My Berry, Love Me Tender og
              Romance ásamt 2-in-1 undir/yfirlakki.
            </BagListItem>
          </BagDescription>
          <BagPrice>9.000 kr.</BagPrice>
        </BagTextWrapper>
        <BagImageContainer>
          <BagImage
            src={require('../assets/fjaroflun/nailberry-4-minni.png')}
          />
        </BagImageContainer>
      </BagContainer>
      <BagContainer className="border-bottom">
        <BagImageContainer leftAligned>
          <BagImage src={require('../assets/fjaroflun/serviettur-minni.png')} />
        </BagImageContainer>
        <BagTextWrapper rightAligned>
          <BagName>Servíettur</BagName>
          <BagDescription>
            <BagListItem>
              Servíettur í tveimur stærðum með merki og slagorði kórsins „Allt
              er gott og ekkert skiptir máli“. Hannað í samstarfi við Reykjavík
              Letterpress.
            </BagListItem>
          </BagDescription>
          <BagPrice>12 x 12 cm 1.400 kr.</BagPrice>
          <BagPrice>16,5 x 16,5 cm 1.500 kr.</BagPrice>
        </BagTextWrapper>
      </BagContainer>
      <BagContainer className="border-bottom" columnReverse>
        <BagTextWrapper>
          <BagName>Dekurpakki frá Hydréa</BagName>
          <BagDescription>
            <BagListItem>
              Snyrtibudda sem inniheldur höfuðband, loofah og andlitsbursta.
            </BagListItem>
          </BagDescription>
          <BagPrice>3.500 kr.</BagPrice>
        </BagTextWrapper>
        <BagImageContainer>
          {/* <BagImage src={require('../assets/fjaroflun/dekurpakki-litill.png')} /> */}
          <BagImage
            src={require('../assets/fjaroflun/dekurpakki1-minni.png')}
          />
        </BagImageContainer>
      </BagContainer>
      <BagContainer className="border-bottom">
        <BagImageContainer leftAligned>
          {/* <BagImage src={require('../assets/fjaroflun/dekurpakki-stor.png')} /> */}
          <BagImage
            src={require('../assets/fjaroflun/dekurpakki2-minni.png')}
          />
        </BagImageContainer>
        <BagTextWrapper rightAligned>
          <BagName>Stór dekurpakki frá Hydréa</BagName>
          <BagDescription>
            <BagListItem>
              Snyrtibudda sem inniheldur höfuðband, nuddhanska og bambus
              augnhvílu með ilm af lavender. Hægt er að nota augnhvíluna hvoru
              tveggja heita og kalda.
            </BagListItem>
          </BagDescription>
          <BagPrice>5.000 kr.</BagPrice>
        </BagTextWrapper>
      </BagContainer>
      <BagContainer columnReverse className="border-bottom">
        <BagTextWrapper>
          <BagName>Dekurstund heima 1</BagName>
          <BagDescription>
            <BagListItem>
              Snyrtibudda sem inniheldur höfuðband, loofah og andlitsbursta.
            </BagListItem>
            <BagListItem>
              Pakki með tveimur naglalökkum frá Nailberry, sérvöldum af Kötlum.
              Naglalökkin eru í litunum Flapper og Rouge.
            </BagListItem>
            {/* <BagListItem>
              Gómsætt súkkulaði úr Winter Collection frá Om Nom, hægt er að
              velja Dark Nibs + Raspberry eða Spiced White + Caramel.{' '}
            </BagListItem> */}
            <BagListItem>
              Tækifæriskort með merki og slagorði kórsins "Allt er gott og
              ekkert skiptir máli". Kortin koma fjögur saman í pakka.
            </BagListItem>
            <BagListItem>Merktur taupoki</BagListItem>
          </BagDescription>
          <BagPrice>8.500 kr.</BagPrice>
        </BagTextWrapper>
        <BagImageContainer>
          {/* <BagImage src={require('../assets/fjaroflun/dekurstund1.png')} /> */}
          <BagImage
            src={require('../assets/fjaroflun/dekurstund1_m_kortum.png')}
          />
        </BagImageContainer>
      </BagContainer>
      <BagContainer className="border-bottom">
        <BagImageContainer leftAligned>
          {/* <BagImage src={require('../assets/fjaroflun/dekurstund2.png')} /> */}
          <BagImage
            src={require('../assets/fjaroflun/dekurstund2_m_kortum.png')}
          />
        </BagImageContainer>
        <BagTextWrapper rightAligned>
          <BagName>Dekurstund heima 2</BagName>
          <BagDescription>
            <BagListItem>
              Snyrtibudda sem inniheldur höfuðband, nuddhanska og bambus
              augnhvílu með ilm af lavender. Hægt er að nota augnhvíluna hvoru
              tveggja heita og kalda.
            </BagListItem>
            <BagListItem>
              Falleg gjafaaskja frá Nailberry með fjórum lökkum sérvöldum af
              Kötlum. Lökkin eru í litunum Pop My Berry, Love Me Tender og
              Romance ásamt 2-in-1 undir/yfirlakki.
            </BagListItem>
            {/* <BagListItem>
              Gómsætt súkkulaði úr Winter Collection frá Om Nom, hægt er að
              velja Dark Nibs + Raspberry eða Spiced White + Caramel.{' '}
            </BagListItem> */}
            <BagListItem>
              Tækifæriskort með merki og slagorði kórsins "Allt er gott og
              ekkert skiptir máli". Kortin koma fjögur saman í pakka.
            </BagListItem>
            <BagListItem>Merktur taupoki</BagListItem>
          </BagDescription>
          <BagPrice>11.900 kr.</BagPrice>
        </BagTextWrapper>
      </BagContainer>
      <BagContainer className="border-bottom" columnReverse>
        <BagTextWrapper>
          <BagName>Gestgjafinn</BagName>
          <BagDescription>
            <BagListItem>
              Servíettur í tveimur stærðum með merki og slagorði kórsins „Allt
              er gott og ekkert skiptir máli“. Hannað í samstarfi við Reykjavík
              Letterpress. (12 x 12 cm og 16,5 x 16,5 cm)
            </BagListItem>
            <BagListItem>
              Tveir pakkar af tækifæriskortum með merki og slagorði kórsins
              "Allt er gott og ekkert skiptir máli". Kortin koma fjögur saman í
              pakka.
            </BagListItem>
            <BagListItem>Merktur taupoki</BagListItem>
          </BagDescription>
          <BagPrice>5.500 kr.</BagPrice>
        </BagTextWrapper>
        <BagImageContainer>
          {/* <BagImage src={require('../assets/fjaroflun/gestgjafinn.png')} /> */}
          <BagImage
            src={require('../assets/fjaroflun/gestgjafinn_m_kortum.png')}
          />
        </BagImageContainer>
      </BagContainer>
      {/* Single bag image left aligned */}
      <BagContainer>
        <BagImageContainer leftAligned>
          <BagImage src={require('../assets/pokasala/tomur-poki.png')} />
        </BagImageContainer>
        <BagTextWrapper rightAligned>
          <BagName>Kötlupokinn</BagName>
          <BagDescription>
            <BagListItem>Merktur taupoki</BagListItem>
          </BagDescription>
          <BagPrice>1.000 kr.</BagPrice>
        </BagTextWrapper>
      </BagContainer>
      {/* Single bag image right aligned */}
      {/* <BagContainer columnReverse>
        <BagTextWrapper>
          <BagName>Kötlupokinn</BagName>
          <BagDescription>
            <BagListItem>Merktur taupoki</BagListItem>
          </BagDescription>
          <BagPrice>1.000 kr.</BagPrice>
        </BagTextWrapper>
        <BagImageContainer>
          <BagImage src={require('../assets/pokasala/tomur-poki.png')} />
        </BagImageContainer>
      </BagContainer> */}
    </Root>
  )
}

const Root = styled.div`
  font-family: Geometria;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: rgba(207, 156, 160, 0.8);
  padding: 4rem 4rem 2rem 4rem;

  .border-bottom {
    border-bottom: 0.125rem solid rgba(128, 128, 128, 0.2);
  }

  @media screen and (max-width: 800px) {
    padding: 3rem 1.5rem 1.5rem 1.5rem;
  }
`
const PageHeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  max-width: 65rem;

  @media screen and (max-width: 800px) {
    margin-bottom: 1rem;
  }
`
const PageTitle = styled.h1`
  font-family: Geometria-Medium;
  font-size: 4rem;

  @media screen and (max-width: 800px) {
    font-size: 3rem;
  }
`

const PageDescription = styled.p`
  font-size: 1.25rem;
`

const PageInstructions = styled.div`
  font-size: 1.25rem;
  margin-top: 3rem;
`

const InstructionsList = styled.ol`
  font-size: 1rem;
  text-align: left;
  padding-left: 0;
  margin-left: 4rem;

  @media screen and (max-width: 800px) {
    margin-left: 2rem;
  }
`

const DescriptionList = styled(InstructionsList)`
  @media screen and (max-width: 800px) {
    margin-left: 1rem;
  }
`

const InstructionsListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;

  & > a {
    text-decoration: none;
    font-size: 1rem;
    color: black;

    &:hover {
      text-decoration: underline;
    }
  }
`

const DescriptionListItem = styled(InstructionsListItem)`
  list-style-type: none;
`
const BagContainer = styled.div<{ columnReverse?: boolean }>`
  padding: 4rem 2rem;
  margin: 2rem;
  width: 100%;
  min-height: 31rem;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    padding: 4rem 0;
    margin: 2rem 0;
  }

  @media screen and (max-width: 800px) {
    flex-direction: ${(props) =>
      props.columnReverse ? 'column-reverse' : 'column'};
    align-items: center;

    padding: 0 0 1rem 0;
  }
`

const BagImageContainer = styled.div<{ leftAligned?: boolean }>`
  max-width: 49%;
  display: flex;
  align-items: center;

  padding: 0;
  margin: ${(props) => (props.leftAligned ? '0 2rem 0 0' : '0 0 0 2rem')};

  @media screen and (max-width: 800px) {
    max-width: 100%;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
`

const BagImage = styled.img`
  padding: 2rem;
  background: rgba(255, 255, 255, 1);
  display: flex;
  max-width: 100%;

  @media screen and (max-width: 1024px) {
    max-width: calc(100% - 4rem);
    max-height: calc(100% - 2rem);
  }
`

const BagTextWrapper = styled.span<{ rightAligned?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 49%;

  margin: ${(props) => (props.rightAligned ? '0 0 0 4rem' : '0 4rem 0 0')};

  @media screen and (max-width: 1024px) {
    margin: ${(props) => (props.rightAligned ? '0 0 0 2rem' : '0 2rem 0 0')};
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`

const BagDescription = styled.ul`
  font-size: 1.25rem;
  text-align: left;
  padding-left: 0;

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
  }
`
const BagListItem = styled.li`
  list-style-type: none;
  margin-bottom: 1rem;
`
const BagName = styled.h1`
  font-family: Geometria-Medium;
  font-size: 2.5rem;
  font-weight: 400;
  text-align: left;

  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }
`

const BagPrice = styled.p`
  font-size: 2rem;
  margin-top: 0;

  @media screen and (max-width: 800px) {
    font-size: 1.5rem;
    align-self: flex-end;
  }
`

/* #e1ad9d */
