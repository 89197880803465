import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

interface IProps {
  itemName: string
  anchorName: string
  onClick?: () => any
  active?: any
}
/*
 * A single menu item
 * I deconstruct props to provide more readable code, allowing
 * any future coders to see exactly what props are expected
 */
const MenuItem = ({ itemName, anchorName, onClick, active }: IProps) => {
  /*
   * Store our anchorTarget in state
   * We do not set it here, preferring to wait for after the component
   * is mounted to avoid any errors
   */
  const [anchorTarget, setAnchorTarget] = useState(null)

  /*
   * When the component mounts and/or updates, set our AnchorTarget based
   * on the anchorName
   */
  useEffect(() => {
    //@ts-ignore
    setAnchorTarget(document.getElementById(anchorName))
  }, [anchorName])

  /*
   * Where all the magic happens -- scrollIntoView on click
   */
  const handleClick = (event: any) => {
    event.preventDefault()
    //@ts-ignore
    anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
    onClick && onClick()
  }

  /*
   * Return the MenuItem as JSX
   * Remember to set your ariaLabel for accessability!
   */
  return (
    <MenuLink
      href={`#${anchorName}`}
      onClick={handleClick}
      className={active}
      aria-label={`Scroll to ${anchorName}`}
    >
      {itemName}
    </MenuLink>
  )
}

export default MenuItem

export const MenuLink = styled.a`
  color: black;
  font-family: Geometria;
  text-decoration: none;
  font-size: 1.25rem;
  transition: all 320ms ease;
  position: relative;

  /* &:hover {
    cursor: pointer;
  } */

  &:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 50%;
    /* left: 0; */
    position: absolute;
    background: black;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
    /* width: 100%; */
  }
  &:hover:after {
    width: 100%;
    left: 0;
  }

  @media (max-width: 400px) {
    margin: 1rem;
  }
`
