import React from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  text: string
  title: string
  children: any
  id: string
  photoPosition: 'left' | 'right'
  url?: string
  urlText?: string
}

export const TextImageSection: React.FunctionComponent<IProps> = ({
  text,
  title,
  children,
  id,
  photoPosition,
  url,
  urlText,
}: IProps) => {
  return (
    <Root id={id} className="text-image-section">
      <ContentWrapper reverse={photoPosition === 'right'}>
        {photoPosition === 'left' && (
          <PhotoWrapper isRight={false}>{children}</PhotoWrapper>
        )}
        <TextContainer isRight={photoPosition === 'right'}>
          <Title>{title}</Title>
          <TextDescription>{text}</TextDescription>
          {url && <TextUrl href={url}>{urlText}</TextUrl>}
        </TextContainer>
        {photoPosition === 'right' && (
          <PhotoWrapper isRight={photoPosition === 'right'}>
            {children}
          </PhotoWrapper>
        )}
      </ContentWrapper>
    </Root>
  )
}

const Root = styled.div`
  padding: 7rem 4rem 7rem 4rem;

  @media (max-width: 400px) {
    padding: 1rem;
  }
`

const ContentWrapper = styled.div<{ reverse: boolean }>`
  ${({ reverse }) => css`
    display: flex;
    align-items: stretch;
    border-radius: 1rem;
    box-shadow: 0 1rem 5rem 0.25rem rgba(0, 0, 0, 0.16);
    max-width: 106rem;
    @media (max-width: 400px) {
      flex-direction: ${reverse ? 'column-reverse' : 'column'};
    }
  `}
`

const TextContainer = styled.div<{ isRight: boolean }>`
  ${({ isRight }) => css`
    background-color: white;
    padding: 2rem 5rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: ${isRight ? '1rem 0 0 1rem' : '0 1rem 1rem 0'};
    text-align: left;

    @media (max-width: 400px) {
      padding: 1rem 2rem;
      width: 80%;
      border-radius: 1rem 1rem;
    }
  `}
`
const Title = styled.h2`
  font-family: Existence Light;
  font-size: 2rem;
  font-weight: 400;
`

const TextDescription = styled.p`
  font-size: 1.25rem;
  line-height: 1.45;
  font-family: Geometria;
  @media (max-width: 400px) {
    font-size: 1rem;
  }
`
const TextUrl = styled.a`
  font-size: 1.25rem;
  line-height: 1.45;
  font-family: Geometria;
  margin-bottom: 1rem;
  color: black;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease-in-out;
  background-color: white;

  &:hover {
    background-color: rgba(207, 156, 160, 0.8);
    color: white;
    box-shadow: 5px 5px 5px 0px rgb(0 0 0 / 14%);
  }
  @media (max-width: 400px) {
    font-size: 1rem;
    padding: 0;
  }
`
const PhotoWrapper = styled.div<{ isRight: boolean }>`
  ${({ isRight }) => css`
    width: 50%;
    border-radius: ${isRight ? '0 1rem 1rem 0' : '1rem 0 0 1rem'};

    @media (max-width: 400px) {
      padding: 1rem;
      width: 90%;
      border-radius: 1rem 1rem;
    }
  `}
`
