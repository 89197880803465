import React from 'react'
import styled from 'styled-components'

export const Program: React.FC = () => {
  return (
    <Root>
      <PageHeadingContainer>
        <Logo src={require('../assets/katla-logo.png')} />
        <PageTitle>Kötlugos</PageTitle>
        <PageSubTitle>- Kvennakórinn Katla 10 ára - </PageSubTitle>

        <PageInstructions>
          <ProgramList>
            <ProgramListItem>
              <strong>Just for Now</strong> - Imogen Heap
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Time After Time</strong> - Cindy Lauper
              <ProgramListInfo>Aðlöguð útsetning kórstýra</ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Hvað um mig og þig?</strong> - Ragnhildur Gísladóttir
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>White Rabbit</strong> - Jefferson Airplane
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Hide and Seek</strong> - Imogen Heap
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Dreams</strong> - Cranberries
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Jóga</strong> - Björk
              <ProgramListInfo>Útsetning: Marianne Søgaard</ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Peeling Off the Layers</strong> - Wildbirds & Peacedrums
              <ProgramListInfo>
                Útsetning: Hildur Guðnadóttir
                <br />
                Aðlögun að kvennakór: Hildigunnur Einarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Ergen Deda</strong> - Búlgarskt þjóðlag
              <ProgramListInfo>Útsetning: Óþekkt</ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Kuka nukkuu tuutussasi</strong> - Anna-Mari Kähärä
            </ProgramListItem>
            <ProgramListItem>
              <strong>Take My Breath Away</strong> - Giorgio Moroder og Tom
              Whitlock
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Wuthering Heights</strong> - Kate Bush
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Hamingjusyrpa</strong> - GDRN / Jói Pé, RAKEL og CeaseTone
              / <br />
              FLOTT / Jón Jónsson / Jónas Sig / Moses Hightower
              <ProgramListInfo>Útsetning: María Magnúsdóttir</ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Water Fountain</strong> - Tune-Yards
              <ProgramListInfo>Útsetning: María Magnúsdóttir</ProgramListInfo>
            </ProgramListItem>
          </ProgramList>
        </PageInstructions>
      </PageHeadingContainer>
      <AdSection>
        <AdWrapper>
          <AdLink href="https://www.ojk.is/">
            <Ad src={require('../assets/logo-efnisskra-2022/ojk.jpg')}></Ad>
          </AdLink>
        </AdWrapper>
        <AdWrapper>
          <AdLink href="https://www.isam.is/">
            <Ad src={require('../assets/logo-efnisskra-2022/isam.jpg')}></Ad>
          </AdLink>
        </AdWrapper>
        <AdWrapper>
          <AdLink href="https://www.mena.is/">
            <Ad
              src={require('../assets/logo-efnisskra-2022/mena-litid.png')}
            ></Ad>
          </AdLink>
        </AdWrapper>
        <AdWrapper>
          <AdLink href="https://www.landsvirkjun.is/">
            <Ad
              src={require('../assets/logo-efnisskra-2022/landsvirkjun.png')}
            ></Ad>
          </AdLink>
        </AdWrapper>
        <AdWrapper>
          <AdLink href="https://www.unique.is/">
            <Ad src={require('../assets/logo-efnisskra-2022/unique.png')}></Ad>
          </AdLink>
        </AdWrapper>
        <AdWrapper>
          <AdLink href="https://www.dekra.is/">
            <Ad
              src={require('../assets/logo-efnisskra-2022/nailberry_logo.png')}
            ></Ad>
          </AdLink>
        </AdWrapper>
      </AdSection>
    </Root>
  )
}

const Root = styled.div`
  font-family: Geometria;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: rgba(225, 173, 157, 0.8);
  padding: 2rem 4rem;
  min-height: 100vh;

  .border-bottom {
    border-bottom: 0.125rem solid rgba(128, 128, 128, 0.2);
  }

  @media screen and (max-width: 800px) {
    padding: 1.5rem;
  }
`
const PageHeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  max-width: 65rem;

  @media screen and (max-width: 800px) {
    margin-bottom: 1rem;
  }
`
const Logo = styled.img`
  height: 6rem;
  width: 6rem;
`
const PageTitle = styled.h1`
  font-family: Geometria-Medium;
  font-size: 4rem;

  margin-top: 1rem;

  @media screen and (max-width: 800px) {
    font-size: 3rem;
  }
`
const PageSubTitle = styled.h3`
  font-family: Geometria-Medium;
  margin-top: -2.5rem;
  margin-bottom: 2.5rem;

  @media screen and (max-width: 800px) {
    margin-top: -1.5rem;
  }
`
const PageInstructions = styled.div`
  font-size: 1.25rem;
`

const ProgramList = styled.ol`
  font-size: 1rem;
  text-align: left;
  padding-left: 0;
  margin-left: 4rem;
  margin-bottom: 5rem;

  @media screen and (max-width: 800px) {
    margin-left: 2rem;
  }
`

const ProgramListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;

  & > a {
    text-decoration: none;
    font-size: 1rem;
    color: black;

    &:hover {
      text-decoration: underline;
    }
  }
`

const ProgramListInfo = styled.p`
  font-size: 0.875rem;
  margin-top: 0.25rem;
`

const AdSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 0 5rem 0;

  @media screen and (max-width: 1620px) {
    color: magenta;
  }
`

const AdWrapper = styled.div`
  margin: 0 0.5rem;

  @media screen and (max-width: 1620px) {
    color: magenta;
  }

  @media screen and (max-width: 900px) {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  @media screen and (max-width: 768px) {
    margin: 0 0 0.5rem 0;
    width: 100%;
  }
`

const AdLink = styled.a`
  max-height: 100%;
  max-width: 100%;
`

const Ad = styled.img`
  max-width: 100%;
  max-height: 100%;

  /* @media screen and (min-width: 800px) {
    max-width: 55vw;
  } */

  @media screen and (min-width: 1310px) {
    height: 22rem;
  }

  @media screen and (max-width: 900px) {
    margin: 0;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
    width: 100%;
  }
`

/* #e1ad9d */
