import React from 'react'
import styled from 'styled-components'

export const Bags: React.FC = () => {
  return (
    <Root>
      <PageHeadingContainer>
        <PageTitle>Allt er gott og ekkert skiptir máli</PageTitle>
        <PageDescription>
          Kvennakórinn Katla blæs til umhverfisvænnar fjáröflunar vegna útferðar
          kórsins á sumarmánuðum til Brighton, þar sem landinn verður heillaður
          með ægifögrum söng 🎶 Frábært úrval af vörum fyrir þá sem láta sig
          umhverfið varða 🌳
        </PageDescription>
        <DescriptionList>
          <DescriptionListItem>
            💚 Fjölnota taupoki merktur með merki og slagorði kórsins "Allt er
            gott og ekkert skiptir máli".
          </DescriptionListItem>
          <DescriptionListItem>
            💚 Vörur frá versluninni <a href="https://mena.is">Mena</a> sem
            sérhæfir sig í umhverfisvænum vörum.
          </DescriptionListItem>
          <DescriptionListItem>
            💚 Vörur frá Omnom og Kaffitári í umhverfisvænum umbúðum.
          </DescriptionListItem>
        </DescriptionList>
        <PageInstructions>
          Hægt er að nálgast þessa forláta poka á þrjá mismunandi vegu:
          <InstructionsList>
            <InstructionsListItem>
              Það er hægt að senda tölvupóst á{' '}
              <a
                href="mailto:kvennakorinn.katla@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                kvennakorinn.katla@gmail.com
              </a>
            </InstructionsListItem>
            <InstructionsListItem>
              Eða skrifa skilaboð eða kommenta á{' '}
              <a
                href="https://www.facebook.com/kvennakorinnkatla/"
                target="_blank"
                rel="noopener noreferrer"
              >
                facebook-síðu kórsins
              </a>
            </InstructionsListItem>
            <InstructionsListItem>
              Nú eða heyra í uppáhalds Kötlunni þinni
            </InstructionsListItem>
          </InstructionsList>
        </PageInstructions>
      </PageHeadingContainer>
      <BagContainer className="border-bottom">
        <BagImageContainer leftAligned>
          <BagImage src={require('../assets/pokasala/thvottapakki.png')} />
        </BagImageContainer>
        <BagTextWrapper rightAligned>
          <BagName>Þvottapokinn</BagName>
          <BagDescription>
            <BagListItem>
              Lífrænar sápuskeljar fyrir allan þvott (500 gr.). Þvottaefni sem
              vex á trjánum.
            </BagListItem>
            <BagListItem>
              Blettaeyðir fyrir þvott sem virkar vel á flestar gerðir flíka.
              Gerður úr náttúrulegum og niðurbrjótanlegum efnum
            </BagListItem>
            <BagListItem>Merktur taupoki</BagListItem>
          </BagDescription>
          <BagPrice>4.900 kr.</BagPrice>
        </BagTextWrapper>
      </BagContainer>
      <BagContainer className="border-bottom" columnReverse>
        <BagTextWrapper>
          <BagName>Dekurpokinn</BagName>
          <BagDescription>
            <BagListItem>
              Náttúrulegt líkamskrem fyrir allar húðgerðir. Inniheldur lífræna
              ólífuolíu og moringa olíu. Gefur húðinni næringu og vörn gegn
              þurrki í allt að 72 klst.
            </BagListItem>
            <BagListItem>
              Handgerð baðbomba með lavander og rósmarín. Án eiturefna og
              ilmgjafinn er eingöngu frá ilmkjarnaolíum.
            </BagListItem>
            <BagListItem>Merktur taupoki</BagListItem>
          </BagDescription>
          <BagPrice>4.300 kr.</BagPrice>
        </BagTextWrapper>
        <BagImageContainer>
          <BagImage src={require('../assets/pokasala/dekurpakki.png')} />
        </BagImageContainer>
      </BagContainer>
      <BagContainer className="border-bottom">
        <BagImageContainer leftAligned>
          <BagImage src={require('../assets/pokasala/tannburstar-omnom.png')} />
        </BagImageContainer>
        <BagTextWrapper rightAligned>
          <BagName>Sætt og hreint pokinn</BagName>
          <BagDescription>
            <BagListItem>
              Eitt Omnom karamellu súkkulaði úr lífrænum kakóbaunum og tveir
              tannburstar úr bambus án BPA.
            </BagListItem>
            <BagListItem>Merktur taupoki</BagListItem>
          </BagDescription>
          <BagPrice>3.800 kr.</BagPrice>
        </BagTextWrapper>
      </BagContainer>
      <BagContainer className="border-bottom" columnReverse>
        <BagTextWrapper>
          <BagName>Uppþvottapokinn</BagName>
          <BagDescription>
            <BagListItem>
              Plastlaust og náttúrulegt uppþvottasápustykki úr náttúrulegum
              olíum.
            </BagListItem>
            <BagListItem>
              Handgerður uppþvottabursti úr tré og hárin unnin úr trefjum ásamt
              auka haus á uppþvottaburstann.
            </BagListItem>
            <BagListItem>Merktur taupoki</BagListItem>
          </BagDescription>
          <BagPrice>4.900 kr.</BagPrice>
        </BagTextWrapper>
        <BagImageContainer>
          <BagImage src={require('../assets/pokasala/uppthvottapoki.png')} />
        </BagImageContainer>
      </BagContainer>
      <BagContainer className="border-bottom">
        <BagImageContainer leftAligned>
          <BagImage src={require('../assets/pokasala/kaffi-omnom.png')} />
        </BagImageContainer>
        <BagTextWrapper rightAligned>
          <BagName>Kaffi og súkkó pokinn</BagName>
          <BagDescription>
            <BagListItem>
              Einn poki af Hátíðarkaffi frá Kaffitári í umhverfisvænum umbúðum
              og eitt Omnom súkkulaði úr lífrænum kakóbaunum með lakkrís og
              sjávarsalti. Taka þarf fram hvort þú kýst kaffibaunir eða malað
              kaffi.
            </BagListItem>
            <BagListItem>Merktur taupoki</BagListItem>
          </BagDescription>
          <BagPrice>3.800 kr.</BagPrice>
        </BagTextWrapper>
      </BagContainer>
      <BagContainer columnReverse>
        <BagTextWrapper>
          <BagName>Kötlupokinn</BagName>
          <BagDescription>
            <BagListItem>Merktur taupoki</BagListItem>
          </BagDescription>
          <BagPrice>2.500 kr.</BagPrice>
        </BagTextWrapper>
        <BagImageContainer>
          <BagImage src={require('../assets/pokasala/tomur-poki.png')} />
        </BagImageContainer>
      </BagContainer>
    </Root>
  )
}

const Root = styled.div`
  font-family: Geometria;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: rgba(225, 173, 157, 0.8);
  padding: 2rem 4rem;

  .border-bottom {
    border-bottom: 0.125rem solid rgba(128, 128, 128, 0.2);
  }

  @media screen and (max-width: 800px) {
    padding: 1.5rem;
  }
`
const PageHeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  max-width: 65rem;

  @media screen and (max-width: 800px) {
    margin-bottom: 1rem;
  }
`
const PageTitle = styled.h1`
  font-family: Geometria-Medium;
  font-size: 4rem;

  @media screen and (max-width: 800px) {
    font-size: 3rem;
  }
`

const PageDescription = styled.p`
  font-size: 1.25rem;
`

const PageInstructions = styled.div`
  font-size: 1.25rem;
  margin-top: 3rem;
`

const InstructionsList = styled.ol`
  font-size: 1rem;
  text-align: left;
  padding-left: 0;
  margin-left: 4rem;

  @media screen and (max-width: 800px) {
    margin-left: 2rem;
  }
`

const DescriptionList = styled(InstructionsList)`
  @media screen and (max-width: 800px) {
    margin-left: 1rem;
  }
`

const InstructionsListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;

  & > a {
    text-decoration: none;
    font-size: 1rem;
    color: black;

    &:hover {
      text-decoration: underline;
    }
  }
`

const DescriptionListItem = styled(InstructionsListItem)`
  list-style-type: none;
`
const BagContainer = styled.div<{ columnReverse?: boolean }>`
  padding: 4rem 2rem;
  margin: 2rem;
  width: 100%;
  min-height: 31rem;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    padding: 4rem 0;
    margin: 2rem 0;
  }

  @media screen and (max-width: 800px) {
    flex-direction: ${props =>
      props.columnReverse ? 'column-reverse' : 'column'};
    align-items: center;

    padding: 0 0 1rem 0;
  }
`

const BagImageContainer = styled.div<{ leftAligned?: boolean }>`
  max-width: 49%;
  display: flex;
  align-items: center;

  padding: 0;
  margin: ${props => (props.leftAligned ? '0 2rem 0 0' : '0 0 0 2rem')};

  @media screen and (max-width: 800px) {
    max-width: 100%;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
`

const BagImage = styled.img`
  padding: 2rem;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  max-width: 100%;

  @media screen and (max-width: 1024px) {
    max-width: calc(100% - 4rem);
    max-height: calc(100% - 2rem);
  }
`

const BagTextWrapper = styled.span<{ rightAligned?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 49%;

  margin: ${props => (props.rightAligned ? '0 0 0 4rem' : '0 4rem 0 0')};

  @media screen and (max-width: 1024px) {
    margin: ${props => (props.rightAligned ? '0 0 0 2rem' : '0 2rem 0 0')};
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`

const BagDescription = styled.ul`
  font-size: 1.25rem;
  text-align: left;
  padding-left: 0;

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
  }
`
const BagListItem = styled.li`
  list-style-type: none;
  margin-bottom: 1rem;
`
const BagName = styled.h1`
  font-family: Geometria-Medium;
  font-size: 2.5rem;
  font-weight: 400;
  text-align: left;

  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }
`

const BagPrice = styled.p`
  font-size: 2rem;
  margin-top: 0;

  @media screen and (max-width: 800px) {
    font-size: 1.5rem;
    align-self: flex-end;
  }
`

/* #e1ad9d */
