import React, { useEffect, useState } from 'react'
import './App.css'
import './fonts.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Hamburger from 'hamburger-react'
import { Home } from './routes/Home'
import { Bags } from './routes/Bags'
import { Sales2022 } from './routes/Sales2022'
import { Program as Program2020 } from './routes/ProgramFeb2020'
import { Program as Program2022 } from './routes/ProgramMay2022'
import { QR } from './routes/QR'
import styled, { css } from 'styled-components'
import background from './assets/hero-smaller.jpg'
// import Menu from './components/Menu'
import MenuItem, { MenuLink } from './components/MenuItem'
import { ProgramMay2024 } from './routes/ProgramMay2024'

const App: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [noMenu, setNoMenu] = useState(false)

  const handleMenuToggle = () => {
    setShowMenu(!showMenu)
  }

  useEffect(() => {
    setNoMenu(window.location.pathname === '/jol-2022')
  }, [window.location.pathname])

  return (
    <Root>
      <Header>
        <MobileMenuWrapper visible={!noMenu}>
          <Hamburger onToggle={handleMenuToggle} toggled={showMenu} />
        </MobileMenuWrapper>
        <MenuWrapper visible={!noMenu}>
          <MenuItemWrapper>
            <MenuItem
              itemName={'kórinn'}
              anchorName={'korinn'}
              onClick={handleMenuToggle}
            ></MenuItem>
          </MenuItemWrapper>
          <MenuItemWrapper>
            <MenuItem
              itemName={'stýrurnar'}
              anchorName={'styrurnar'}
              onClick={handleMenuToggle}
            ></MenuItem>
          </MenuItemWrapper>
          <MenuItemWrapper>
            <MenuItem
              itemName={'gigg'}
              anchorName={'gigg'}
              onClick={handleMenuToggle}
            ></MenuItem>
          </MenuItemWrapper>
          <MenuItemWrapper>
            <MenuItem
              itemName={'hafðu samband'}
              anchorName={'hafa-samband'}
              onClick={handleMenuToggle}
            ></MenuItem>
          </MenuItemWrapper>
          <MenuItemWrapper>
            <MenuLink href={'/jol-2022'}>fjáröflun</MenuLink>
          </MenuItemWrapper>
        </MenuWrapper>
        {/* <Menu /> */}
        {/* <MobileMenu showMenu={showMenu}>
          <MenuItem
            itemName={'kórinn'}
            anchorName={'korinn'}
            onClick={handleMenuToggle}
          ></MenuItem>
          <MobileSeparator />
          <MenuItem
            itemName={'stýrurnar'}
            anchorName={'styrurnar'}
            onClick={handleMenuToggle}
          ></MenuItem>
          <MobileSeparator />
          <MenuItem
            itemName={'gigg'}
            anchorName={'gigg'}
            onClick={handleMenuToggle}
          ></MenuItem>
          <MobileSeparator />
          <MenuItem
            itemName={'hafa samband'}
            anchorName={'hafa-samband'}
            onClick={handleMenuToggle}
          ></MenuItem>
          <MobileSeparator />
          <MenuLink href={'/jol-2022'}>Fjáröflun</MenuLink>
        </MobileMenu> */}

        {/* Oh no what horrible shitmix 🙃 */}

        <MobileMenuWrapper visible={noMenu}>
          <Hamburger onToggle={handleMenuToggle} toggled={showMenu} />
        </MobileMenuWrapper>
        <MenuWrapper visible={noMenu}>
          <MenuItemWrapper>
            <MenuLink href={'/#korinn'}>kórinn</MenuLink>
          </MenuItemWrapper>
          <MenuItemWrapper>
            <MenuLink href={'/#styrurnar'}>stýrurnar</MenuLink>
          </MenuItemWrapper>
          <MenuItemWrapper>
            <MenuLink href={'/#gigg'}>gigg</MenuLink>
          </MenuItemWrapper>
          <MenuItemWrapper>
            <MenuLink href={'/#hafa-samband'}>hafðu samband</MenuLink>
          </MenuItemWrapper>
          <MenuItemWrapper>
            <MenuLink href={'/jol-2022'}>fjáröflun</MenuLink>
          </MenuItemWrapper>
        </MenuWrapper>
        {/* <Menu /> */}
        <MobileMenu showMenu={showMenu}>
          <MenuLink href={'/#korinn'}>kórinn</MenuLink>
          <MobileSeparator />
          <MenuLink href={'/#styrurnar'}>stýrurnar</MenuLink>
          <MobileSeparator />
          <MenuLink href={'/#gigg'}>gigg</MenuLink>
          <MobileSeparator />
          <MenuLink href={'/#hafa-samband'}>hafðu samband</MenuLink>
          <MobileSeparator />
          <MenuLink href={'/jol-2022'}>Fjáröflun</MenuLink>
        </MobileMenu>
      </Header>
      <Router>
        <Switch>
          <Route path="/pokasala">
            <Bags />
          </Route>
          <Route path="/jol-2022">
            <Sales2022 />
          </Route>
          <Route path="/efnisskra-vor-2024">
            <ProgramMay2024 />
          </Route>
          <Route path="/efnisskra">
            <Program2022 />
          </Route>
          <Route path="/efnisskra-2020">
            <Program2020 />
          </Route>
          <Route path="/qr">
            <QR />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </Root>
  )
}

export default App

const Root = styled.div`
  text-align: center;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 700px) {
    background-position-x: -28rem;
  }
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  position: absolute;
  width: 100%;

  z-index: 500;
`

const MobileMenuWrapper = styled.span<{ visible: boolean }>`
  ${({ visible }) => css`
    position: absolute;
    right: 1rem;
    top: 1rem;

    ${visible ? 'display: block;' : 'display: none;'}

    @media (min-width: 401px) {
      display: none;
    }
  `}
`

const MenuWrapper = styled.ul<{ visible: boolean }>`
  ${({ visible }) => css`
    margin-top: 2rem;
    list-style: none;

    @media (max-width: 400px) {
      display: none;
    }
    ${visible ? 'display: block;' : 'display: none;'}
  `}
`

const MenuItemWrapper = styled.li`
  display: inline-flex;
  padding: 0 1rem;
  &:not(:last-of-type) {
    border-right: 1px solid black;
  }
`

const MobileMenu = styled.div<{ showMenu: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ showMenu }) => css`
    ${showMenu
      ? 'height: 100vh; width: 100vw; background-color: rgba(207, 156, 160, 1);'
      : 'display: none;'}
  `}
  @media (min-width: 401px) {
    display: none;
  }
`

const MobileSeparator = styled.div`
  height: 1px;
  width: 2rem;
  border-bottom: 1px black solid;
  @media (min-width: 401px) {
    display: none;
  }
`
