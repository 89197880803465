import React from 'react'
import styled from 'styled-components'
import { Facebook } from '../assets/Icons/Facebook'
import { Instagram } from '../assets/Icons/Instagram'

export const ProgramMay2024: React.FC = () => {
  return (
    <Root>
      <PageHeadingContainer>
        <Logo src={require('../assets/katla-logo.png')} />
        <PageTitle>Vorblót</PageTitle>
        <PageSubTitle>- Kvennakórinn Katla - </PageSubTitle>

        <PageInstructions>
          <ProgramList>
            <ProgramListItem>
              <strong>In this heart</strong> - Sinéad O´Connor
              <ProgramListInfo>Útsetning: Gunnar Ben</ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Only time</strong> - Enya
              <ProgramListInfo>Útsetning: Julie Gaulke</ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Dreams</strong> - Dolores O'Riordan / Noel Hogan
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Frank Mills</strong> - Galt MacDermot / Gerome Ragni,
              James Rado
              <ProgramListInfo>
                Útsetning: Hildigunnur Einarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>My heart will go on</strong> - James Horner, Will Jennings
              <ProgramListInfo>Útsetning: Michael Pavelich</ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Freedom</strong> - Gunnar Bjarni Ragnarsson / Sigríður
              Guðnadóttir
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Hamingjusyrpa</strong> - GDRN / Jói Pé, RAKEL og CeaseTone
              / <br />
              FLOTT / Jón Jónsson / Jónas Sig / Moses Hightower
              <ProgramListInfo>Útsetning: María Magnúsdóttir</ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Hún ógnar mér</strong> - FLOTT
              <ProgramListInfo>Útsetning: María Magnúsdóttir</ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Í löngu máli</strong> - Una Torfa
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>

            <ProgramListItem>
              <strong>I will survive / Survivor</strong> - Gloria Gaynor /
              Beyoncé, Anthony Dents, Mathew Knowles
              <ProgramListInfo>Útsetning: GLEE</ProgramListInfo>
            </ProgramListItem>

            <ProgramListItem>
              <strong>Total eclipse of the heart</strong> - Bonnie Tyler
              <ProgramListInfo>Útsetning: Teagan Hughes</ProgramListInfo>
            </ProgramListItem>
            <ProgramListItem>
              <strong>Holding out for a hero</strong> - Bonnie Tyler
              <ProgramListInfo>
                Útsetning: Lilja Dögg Gunnarsdóttir
              </ProgramListInfo>
            </ProgramListItem>
          </ProgramList>
        </PageInstructions>
      </PageHeadingContainer>
      <SoMeWrapper>
        <h3>Fylgið okkur á samfélagsmiðlum:</h3>
        <AdLink
          href="https://www.facebook.com/kvennakorinnkatla"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Facebook />
        </AdLink>
        <AdLink
          href="https://www.instagram.com/kvennakorinn_katla/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Instagram />
        </AdLink>
      </SoMeWrapper>
      <AdSection>
        <AdTextWrapper>
          <AdTitle>Kvennakórinn Katla þakkar:</AdTitle>
          <AdLine>Arion banki hf.</AdLine>
          <AdLine>LifeTrack ehf.</AdLine>
        </AdTextWrapper>
        <AdLogosWrapper>
          {/* <AdWrapper>
          <AdLink href="https://www.ojk.is/">
            <Ad src={require('../assets/logo-efnisskra-2022/ojk.jpg')}></Ad>
          </AdLink>
        </AdWrapper> */}
          {/* <AdWrapper>
          <AdLink href="https://www.isam.is/">
            <Ad src={require('../assets/logo-efnisskra-2022/isam.jpg')}></Ad>
          </AdLink>
        </AdWrapper>  */}
          {/* <AdWrapper>
          <AdLink href="https://www.mena.is/">
            <Ad
              src={require('../assets/logo-efnisskra-2022/mena-litid.png')}
            ></Ad>
          </AdLink>
        </AdWrapper>  */}

          <AdWrapper>
            <AdLink
              href="https://www.landsvirkjun.is/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Ad
                src={require('../assets/logo-efnisskra-2022/landsvirkjun.png')}
              ></Ad>
            </AdLink>
          </AdWrapper>
          <AdWrapper>
            <AdLink
              href="https://www.vis.is/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Ad src={require('../assets/logo-efnisskra-2024/vis.avif')}></Ad>
            </AdLink>
          </AdWrapper>
          {/* <AdWrapper>
          <AdLink href="https://www.unique.is/">
            <Ad src={require('../assets/logo-efnisskra-2022/unique.png')}></Ad>
          </AdLink>
        </AdWrapper> */}
          {/* <AdWrapper>
          <AdLink href="https://www.dekra.is/">
            <Ad
              src={require('../assets/logo-efnisskra-2022/nailberry_logo.png')}
            ></Ad>
          </AdLink>
        </AdWrapper> */}
        </AdLogosWrapper>
      </AdSection>
    </Root>
  )
}

const Root = styled.div`
  font-family: Geometria;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: rgba(225, 173, 157, 0.8);
  padding: 2rem 4rem;
  min-height: 100vh;

  .border-bottom {
    border-bottom: 0.125rem solid rgba(128, 128, 128, 0.2);
  }

  @media screen and (max-width: 800px) {
    padding: 1.5rem;
  }
`
const PageHeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  max-width: 65rem;

  margin-top: 5rem;
  @media screen and (max-width: 800px) {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 640px) {
    margin-top: 0;
  }
`
const Logo = styled.img`
  height: 6rem;
  width: 6rem;
`
const PageTitle = styled.h1`
  font-family: Geometria-Medium;
  font-size: 4rem;

  margin-top: 1rem;

  @media screen and (max-width: 800px) {
    font-size: 3rem;
  }
`
const PageSubTitle = styled.h3`
  font-family: Geometria-Medium;
  margin-top: -2.5rem;
  margin-bottom: 2.5rem;

  @media screen and (max-width: 800px) {
    margin-top: -1.5rem;
  }
`
const PageInstructions = styled.div`
  font-size: 1.25rem;
`

const ProgramList = styled.ol`
  font-size: 1rem;
  text-align: left;
  padding-left: 0;
  margin-left: 4rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 800px) {
    margin-left: 2rem;
  }
`

const ProgramListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;

  & > a {
    text-decoration: none;
    font-size: 1rem;
    color: black;

    &:hover {
      text-decoration: underline;
    }
  }
`

const ProgramListInfo = styled.p`
  font-size: 0.875rem;
  margin-top: 0.25rem;
`

const SoMeWrapper = styled.div`
  font-size: 1rem;
  margin-bottom: 1rem;
`

const AdSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 0 5rem 0;

  @media screen and (max-width: 1620px) {
    /* color: magenta; */
  }
`
const AdTextWrapper = styled.div``

const AdTitle = styled.h3``

const AdLine = styled.p`
  font-weight: bold;
  font-size: 2rem;
`
const AdLogosWrapper = styled.div`
  margin-top: 1rem;
`

const AdWrapper = styled.div`
  margin: 0 0.5rem;

  margin-bottom: 1rem;

  @media screen and (max-width: 900px) {
    margin-left: 5rem;
    margin-right: 5rem;
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    margin: 0 0 0.5rem 0;
    width: 100%;
  }
`

const AdLink = styled.a`
  max-height: 100%;
  max-width: 100%;

  &:first-of-type svg {
    margin-right: 2rem;
  }
`

const Ad = styled.img`
  max-width: 100%;
  max-height: 100%;

  /* @media screen and (min-width: 800px) {
    max-width: 55vw;
  } */

  @media screen and (min-width: 1310px) {
    height: 22rem;
  }

  @media screen and (max-width: 900px) {
    margin: 0;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
    width: 100%;
  }
`

/* #e1ad9d */
