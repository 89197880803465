import React from 'react'
import styled from 'styled-components'

export const QR: React.FC = () => {
  return (
    <Root>
      <PageHeadingContainer>
        <Logo src={require('../assets/katla-logo.png')} />
        <PageTitle>Vorblót</PageTitle>
        <PageSubTitle>- Kvennakórinn Katla - </PageSubTitle>
        <PageDescription>
          Beindu myndavélinni á símanum þínum að merkinu hér fyrir neðan og ýttu
          á skilaboðin sem birtast.
        </PageDescription>
        <QRWrapper>
          <img
            src={require('../assets/efnisskra-qr-2024.png')}
            alt="efnisskrá"
          ></img>
        </QRWrapper>
        <AdWrapper>
          {/* <AdLink href="https://www.ojk.is/">
            <Ad src={require('../assets/logo-efnisskra-2022/ojk.jpg')}></Ad>
          </AdLink>
          <AdLink href="https://www.isam.is/">
            <Ad src={require('../assets/logo-efnisskra-2022/isam.jpg')}></Ad>
          </AdLink> */}
        </AdWrapper>
      </PageHeadingContainer>
    </Root>
  )
}

const Root = styled.div`
  font-family: Geometria;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: rgba(225, 173, 157, 0.8);
  /* padding: 2rem 4rem; */
  height: 100vh;

  .border-bottom {
    border-bottom: 0.125rem solid rgba(128, 128, 128, 0.2);
  }

  @media screen and (max-width: 800px) {
    padding: 1.5rem;
  }
`
const PageHeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  max-width: 65rem;

  margin-top: 5rem;
  @media screen and (max-width: 800px) {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 640px) {
    margin-top: 0;
  }
`
const Logo = styled.img`
  height: 6rem;
  width: 6rem;
`
const PageTitle = styled.h1`
  font-family: Geometria-Medium;
  font-size: 4rem;
  margin-top: 1rem;

  @media screen and (max-width: 800px) {
    font-size: 3rem;
  }
`
const PageSubTitle = styled.h3`
  font-family: Geometria-Medium;
  margin-top: -2.5rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 800px) {
    margin-top: -1.5rem;
  }
`
const PageDescription = styled.p`
  font-size: 1.25rem;
`

const QRWrapper = styled.div`
  margin-top: 1rem;

  @media screen and (max-width: 800px) {
    margin-top: 1rem;
  }
`

const AdWrapper = styled.div`
  display: flex;

  max-height: 9rem;
  padding-top: 5rem;
  margin: 0 0.5rem;

  not(first-child) {
    margin-left: 1rem;
  }

  @media screen and (max-width: 1620px) {
    color: magenta;
  }

  @media screen and (max-width: 900px) {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  @media screen and (max-width: 768px) {
    margin: 0 0 0.5rem 0;
    width: 100%;
  }
`

const AdLink = styled.a`
  max-height: 100%;
  max-width: 100%;
`

const Ad = styled.img`
  max-width: 100%;
  max-height: 100%;

  /* @media screen and (min-width: 800px) {
    max-width: 55vw;
  } */

  @media screen and (min-width: 1310px) {
    height: 22rem;
  }

  @media screen and (max-width: 900px) {
    margin: 0;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
    width: 100%;
  }
`
